.us_loginAreaContainerWrapper {
    visibility: visible !important;
    position: relative;

    .us_loginAreaContainerBackground {
        position: absolute;
        right: 0;
        left: 0;
        text-align: center;
        padding-top: 4px;
        @include susy-breakpoint($breakpoint-large...) {
            padding-top: 0;
        }

        .us_loginAreaContainerIcon {
            display: block;
            text-align: center;
            overflow: hidden;
            @include font-size($font300);
            line-height: 1.03333em;

            @include susy-breakpoint($breakpoint-large...) {
                @include font-size((font-size: 24px, line-height: 28px));
            }
        }

        .us_iconSubtitle {
            display: none;

            @include susy-breakpoint($breakpoint-large...) {
                display: block;
                font-size: 12px;
                font-size: 0.75rem;
                line-height: 1.16667em;
                white-space: nowrap;
            }
        }
    }

    .us_loginAreaFallbackLink {
        text-decoration: none;
    }
}

.us_loginFooterLink {
    text-decoration: none;
    cursor: pointer;
}

.us_loginAreaIconServiceBubble {
    font-size: 20px;
    color: $service-color;
    position: absolute;
    top: -4px;
    right: -8px;

    @include susy-breakpoint($breakpoint-large...) {
        right: 5px;
    }

    &.us_loginAreaIconServiceBubbleOpen {
        transform: scale(1, 1);
        transform-origin: 50% 50% 0;
        transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    &.us_loginAreaIconServiceBubbleClose {
        transform: scale(0, 0);
        transform-origin: -20% 148% 0;
        transition: all 400ms cubic-bezier(0.64, 0.05, 0.35, 1);
    }

    &.us_loginAreaIconServiceBubbleHide {
        display: none;
    }
}

.us_loginAreaContainerWithName {
    &.us_footer {
        display: table;
        padding-left: 10px;

        .us_loginArea {
            padding-top: 0;
            width: auto;

            .us_loginAreaIcon {
                @include font-size($font225);
            }

            .us_loginAreaIconBadge {
                top: 17px;
                left: 16px;
            }
        }

        .us_loginAreaNameContainer {
            display: table-cell;
            vertical-align: top;
            padding-left: 15px;

            .us_loginAreaMyAccountHeadline {
                font-size: 13px;
                font-weight: bold;
            }

            .us_loginAreaMyNameOrLogin {
                font-size: 13px;
                text-decoration: underline;

                &:hover {
                    text-decoration: underline;
                }

                @include susy-breakpoint($breakpoint-large...) {
                    text-decoration: none;
                }
            }
        }

    }

    .us_loginArea {
        text-align: center;
        cursor: pointer;
        position: relative;
        padding-top: 4px;
        width: 40px;
        height: 40px;
        text-decoration: none;
        display: block;

        .us_loginAreaIcon {
            display: block;
            text-align: center;
            overflow: hidden;
            @include font-size($font300);
            line-height: 1.03333em;
        }

        .us_personalLoginAreaIcon {
            background-position: center center;
            background-repeat: no-repeat;
            display: block;
            height: 28px;
            width: 35px;

            @include susy-breakpoint($breakpoint-large...) {
                width: 60px;
            }
        }

        .us_loginAreaIconBadge {
            background-color: $availability-green;
            position: absolute;
            top: 21px;
            left: 24px;
        }

        .us_iconSubtitle {
            display: none;
        }
    }

    @include susy-breakpoint($breakpoint-large...) {
        &.us_footer {
            .us_loginArea {
                width: auto;

                .us_loginAreaIcon {
                    @include font-size($font225);
                }

                .us_loginAreaIconBadge {
                    top: 17px;
                    left: 16px;
                }
            }
        }

        .us_loginArea {
            padding-top: 0;
            width: 65px;
            height: auto;

            .us_loginAreaIcon {
                @include font-size((font-size: 24px, line-height: 28px));
            }

            .us_loginAreaIconBadge {
                top: 15px;
                left: 36px;
            }

            .us_iconSubtitle {
                display: block;
                @include font-size($font75);
                line-height: 1.16667em;
                text-align: center;
                white-space: nowrap;
            }
        }

    }
}

.find_header {

    .us_loginAreaContainerWrapper {
        color: #777777;

        .us_loginAreaContainerBackground {
            padding-top: 0;

            .us_loginAreaContainerIcon {
                @include font-size($font225);
                line-height: 1.16667em;

                @include susy-breakpoint($breakpoint-large...) {
                    @include font-size((font-size: 24px, line-height: 28px));
                }
            }

            .us_iconSubtitle {
                display: block;
                font-size: 10px;
                font-size: 0.625rem;
                line-height: 1.2em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @include susy-breakpoint($breakpoint-large...) {
                    font-size: 12px;
                    font-size: 0.75rem;
                    line-height: 1.16667em;
                    overflow: visible;
                    text-overflow: unset;
                }
            }
        }
    }


    .us_loginAreaContainerWithName {
        text-decoration: none;

        .us_loginArea {
            padding-top: 0;
            width: auto;

            @include susy-breakpoint($breakpoint-large...) {
                width: auto;
            }

            .us_loginAreaIconBadge {
                top: 13px;
                left: calc(50% + 2px);
            }
        }

        .us_loginAreaIcon {
            display: block;
            text-align: center;
            overflow: hidden;
            @include font-size($font225);
            line-height: 1.16667em;
            cursor: pointer;
        }

        .us_iconSubtitle {
            display: block;
            font-size: 10px;
            font-size: 0.625rem;
            line-height: 1.2em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include susy-breakpoint($breakpoint-large...) {
                font-size: 12px;
                font-size: 0.75rem;
                line-height: 1.16667em;
                overflow: visible;
                text-overflow: unset;
            }
        }
    }

    &.find_header--reduced {
        .us_loginAreaContainerBackground {
            .us_iconSubtitle {
                display: none;

                @include susy-breakpoint($breakpoint-large...) {
                    display: block;
                }
            }
        }

        .us_loginAreaContainerWithName {

            .us_loginAreaIcon {
                line-height: 1.6em;

                @include susy-breakpoint($breakpoint-large...) {
                    line-height: 1.16667em;
                }
            }

            .us_loginAreaIconBadge {
                left: calc(50% + 2px);
                top: 18px;

                @include susy-breakpoint($breakpoint-large...) {
                    top: 13px;
                }
            }

            .us_iconSubtitle {
                display: none;

                @include susy-breakpoint($breakpoint-large...) {
                    display: block;
                }
            }
        }
    }
}

.us_loginMenu {
    position: absolute;
    right: -79px;
    top: 51px;
    width: 284px;
    cursor: auto;
    background: $white;
    border: 1px solid $grey200;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-box-shadow: 0 0 9px rgba(34, 34, 34, 0.3);
    -webkit-box-shadow: 0 0 9px rgba(34, 34, 34, 0.3);
    box-shadow: 0 0 9px rgba(34, 34, 34, 0.3);
    text-align: left;
    z-index: 9999;
    width: auto;
    display: none;

    @include susy-breakpoint($breakpoint-large...) {
        right: -65px;
        top: 60px;
    }

    &:before,
    &:after {
        border-style: solid;
        border-width: 0 15px 15px 15px;
        content: "";
        display: block;
        position: absolute;
        left: 170px;
        width: 0;
        z-index: 1;
    }

    &:before {
        border-color: $grey200 transparent;
        bottom: auto;
        top: -15px;
    }

    &:after {
        border-color: $white transparent;
        bottom: auto;
        top: -14px;
    }

    .us_closeLoginMenuButton {
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 1rem;
    }

    ul {
        margin: 7px 0 16px 0;

        .us_loginAreaMenuItems {
            &.us_bottomLine {
                padding-bottom: 7px;
                border-bottom: 1px solid $grey200;
                margin-bottom: 5px;
            }

            &.us_bottomLine-serviceLink {
                border-bottom: 1px solid $grey200;
                padding-bottom: 15px;

                @include susy-breakpoint($breakpoint-large...) {
                    padding-bottom: 7px;
                    border-bottom: 1px solid $grey200;
                    margin-bottom: 5px;
                }
            }

            span {
                cursor: pointer;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    li {
        width: 284px;
        padding: 10px 15px 0 15px;

        &.us_service_link {
            border-bottom: 1px solid $grey200;
            padding-top: 0px;
            padding-bottom: 16px;
            margin-bottom: 10px;

          @include susy-breakpoint($breakpoint-large...) {
            display: none;
          }
        }

        &.login_area_overview_link {

            padding-bottom: 3px;

            a, span {
                width: 100%;
                display: block;
            }
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        button {
            width: 100%;
            text-align: center;
        }

        &.login_area_menu_button {
            padding-top: 10px;

            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        &.login_area_menu_link {
            width: 284px;
            padding-bottom: 5px;

            &.non_responsive_menu_item {
                display: none;
            }

            a, span {
                display: block;
                width: 100%;
            }

            @include susy-breakpoint($breakpoint-large...) {
                &.non_responsive_menu_item {
                    display: block;

                    &.login_area_menu_hide_link {
                        display: none;
                    }
                }
            }
        }

        .hint_text {
            color: $grey300;
        }

        &.us_loginAreaMenuUserName {
            margin-bottom: 0;
            text-align: center;

            span {
                text-decoration: none;
            }

            a {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;
            }

        }

        &.login_area_menu_visitor_name {
            text-align: center;

            .us_loginAreaMenuUserName {
                text-decoration: none;
                @include font-size($font100);
            }

            .us_loginAreaMenuNotMyName {
                white-space: nowrap;
            }

            .login_area_menu_visitor_notmyname_link {
                text-decoration: underline;
            }
        }

        &.login_area_register_link {
            margin-top: 5px;
            text-align: center;

            a {
                text-decoration: underline;
                vertical-align: baseline;
            }
        }

        &.login_area_menu_hide_link {
            display: none;

            &.us_visibleOnMinorBreakpoints {
                display: block;

                @include susy-breakpoint($breakpoint-large...) {
                    display: none;
                }
            }
        }

        &.login_area_logout_link {
            span {
                text-decoration: none;
            }

            a {
                display: block;
                width: 100%;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}